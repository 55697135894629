/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
#container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}

#container a {
    text-decoration: none;
}

.splitPane {
    margin-top: 60px;
}

.sub-item {
    padding-left: 25px;
}

.sub-item-icon {
    margin-right: 10px;
}

.logo {
    margin-left: 15px;
}

.sidebar-menuList {
    width: 350px;
}

.createJobBtn {
    margin-bottom: 10px;
}

.headerSec {
    border-bottom: 1px solid #80808075;
}

.titleSec {
    margin-bottom: 5px;
}

.header-row {
    font-weight: 500;
    border-bottom: 2px solid #000;
}

.data-row {
    border-bottom: 1px solid #000;
}

.filterSec,
.searchBox {
    padding: 0;
    margin-bottom: 5px;
}

.searchbar-left-aligned {
    padding-left: unset;
    -webkit-padding-start: 30px;
    padding-inline-start: 0px !important;
}

.searchbar-input {
    padding-left: 40px !important;
    padding-right: 28px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    height: 100% !important;
    font-size: 17px !important;
    font-weight: 400 !important;
    contain: strict;
}

.searchbar-input {
    background: rgb(237 237 237) !important;
    border-radius: 10px !important;
}

.swapIcon {
    margin-left: 15px;
    margin-top: 5px;
}

// .welcome-card {
//     margin-top: 80px;
// }
.sc-ion-modal-md-h {
    --width: 310px !important;
    --height: 388px !important;
    --ion-safe-area-top: 0px;
    --ion-safe-area-bottom: 0px;
    --ion-safe-area-right: 0px;
    --ion-safe-area-left: 0px;
}

.questionSet-modal .modal-wrapper {
    --border-radius: 10px;
    --height: 30%;
}

.add-questionList-modal .modal-wrapper {
    --border-radius: 10px;
    --height: 45%;
}

.interviewScheduler-modal .modal-wrapper {
    width: 35%;
    --border-radius: 10px;
    --height: 60%;
}

.interviewer-modal .modal-wrapper {
    width: 50%;
    --border-radius: 10px;
    --height: 62%;
}

ion-button.md.button.button-block.button-default.button-solid.ion-activatable.ion-focusable.hydrated {
    margin-left: 10px;
}

.editIcon,
.plusIcon {
    cursor: pointer;
}

ion-button.ion-color.ion-color-primary.md.button.button-default.button-solid.ion-activatable.ion-focusable.hydrated {
    margin-left: 10px;
}

@media only screen and (max-width: 768px) {
    .interviewScheduler-modal .modal-wrapper,
    .interviewer-modal .modal-wrapper {
        width: 90%;
    }
}

